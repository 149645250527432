import type { Dispatch } from 'react';
import ColorChangeSwatch from '../shared/color-change-swatch';
import type { ColorAction } from '../../lib/color-reducer';

type RelatedColorPaletteProps = {
  title: string;
  hexColors: string[];
  dispatch: Dispatch<ColorAction>;
};

const RelatedColorPalette = ({
  title,
  hexColors,
  dispatch
}: RelatedColorPaletteProps) => {
  return (
    <section>
      <h3 className="mb-4">{title}</h3>
      <div className="grid grid-cols-3 gap-2">
        {hexColors.map((hexColor) => {
          return (
            <ColorChangeSwatch
              key={hexColor}
              hexColor={hexColor}
              className="w-full h-full"
            />
          );
        })}
      </div>
    </section>
  );
};

export default RelatedColorPalette;
