import { type PropsWithChildren, createContext, type Dispatch, useReducer } from "react";
import { InitialColorState, colorReducer, type ColorAction } from "./lib/color-reducer";

type ColorContextState = {
    hexColor: string;
    dispatch: Dispatch<ColorAction>;
    };

export const ColorContext = createContext<ColorContextState>( { 
    hexColor: '#bada55',
} as ColorContextState);

export const ColorProvider = ({ children }: PropsWithChildren) => {

    const [state, dispatch] = useReducer(colorReducer, InitialColorState);
    const { hexColor } = state;

  return (
    <ColorContext.Provider value={{ hexColor, dispatch }}>
      {children}
    </ColorContext.Provider>
  );
}