import { rgb, hsl, hsv, cmyk } from 'color-convert';

export type UpdateHexColorAction = {
    type: 'update-hex-color';
    payload: {
        hexColor: string;
    }
};

type RGBColor = [ number, number, number ];

type HSLColor = [ number, number, number ];

type HSVColor = [ number, number, number ];

type CMYKColor = [ number, number, number, number ];

type ColorState = {
    hexColor: string;
    rgbColor: RGBColor;
    hslColor: HSLColor;
    hsvColor: HSVColor;
    cmykColor: CMYKColor;
};

export const InitialColorState: ColorState = {
    hexColor: '#bada55',
    rgbColor: [ 186, 218, 85 ],
    hslColor: [ 75, 64, 60 ],
    hsvColor: [ 75, 61, 85 ],
    cmykColor: [15, 0, 61, 15],
};

type UpdateRGBColorAction = {
    type: 'update-rgb-color';
    payload: {
        rgb: RGBColor;
    }
};

type UpdateHSLColorAction = {
    type: 'update-hsl-color';
    payload: {
        hsl: HSLColor;
    }
};

type UpdateHSVColorAction = {
    type: 'update-hsv-color';
    payload: {
        hsv: [ number, number, number ];
    }
};

type UpdateCMYKColorAction = {
    type: 'update-cmyk-color';
    payload: {
        cmyk: [ number, number, number, number ];
    }
};

export type ColorAction = UpdateHexColorAction | UpdateRGBColorAction | UpdateHSLColorAction | UpdateHSVColorAction | UpdateCMYKColorAction;

export const colorReducer = (state: ColorState, action: ColorAction): ColorState => {
    switch (action.type) {
        case 'update-hex-color':
            return {
                ...state,
                hexColor: action.payload.hexColor
            };
        case 'update-rgb-color': {
            const hexColor = `#${rgb.hex(action.payload.rgb)}`;
            return {
                ...state,
                hexColor
            };
        }
        case 'update-hsl-color': {
            const hexColor = `#${hsl.hex(action.payload.hsl)}`;
            return {
                ...state,
                hexColor
            }
        }
        case 'update-hsv-color': {
            const hexColor = `#${hsv.hex(action.payload.hsv)}`;
            return {
                ...state,
                hexColor
            }
        }
        case 'update-cmyk-color': {
            const hexColor = `#${cmyk.hex(action.payload.cmyk)}`;
            return {
                ...state,
                hexColor
            }
        }
        default:
            return state;
    }
}
